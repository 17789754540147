












import { computed, defineComponent, PropType } from "@vue/composition-api"

import { useI18n } from "@/_i18n"
import { createDefaultPermissions, PermissionMap } from "@/_service/user"

export default defineComponent({
  name: "PermissionInput",

  props: {
    value: {
      type: Object as PropType<PermissionMap>,
      default: createDefaultPermissions(),
    },
  },

  setup(props, ctx) {
    const attrs = computed(() => {
      return ctx.attrs
    })
    const listeners = computed(() => {
      // workaround to prevent double setting of value on change:
      // the `input` event need to be handled once (in `permissions` setter)
      // eslint-disable-next-line
      const { input, ...listeners } = ctx.listeners // the default `input` listener is ignored
      return listeners
    })

    const { t } = useI18n(
      require.context("./", true, /PermissionInput\.[\w-]+\.(json|ya?ml)$/i)
    )

    const permissions = computed<Array<keyof PermissionMap>>({
      get() {
        const key = Object.keys(props.value) as Array<keyof PermissionMap>
        return key.filter((key) => !!props.value?.[key])
      },
      set(newValue: Array<keyof PermissionMap>) {
        const newPermission = createDefaultPermissions()
        newValue.forEach(
          (key: keyof PermissionMap) => (newPermission[key] = true)
        )
        ctx.emit("input", newPermission)
      },
    })

    const defaultPermissions = createDefaultPermissions()

    const items = computed(() =>
      Object.keys(defaultPermissions)
        .map((key) => ({
          value: key,
          text: t(key) as string,
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    )

    return {
      attrs,
      items,
      listeners,
      permissions,
    }
  },
})
