


















































import { computed, defineComponent, PropType, Ref } from "@vue/composition-api"

import { useI18n } from "@/_i18n"

import {
  createDefaultPermissions,
  PermissionMap,
  UserData,
} from "@/_service/user"

import ClientSelect from "@/component/ClientSelect.vue"
import Password from "@/component/Password.vue"
import PermissionInput from "@/component/PermissionInput.vue"

function propWrapper<O, P>(
  propVal: O,
  target: Ref<O>,
  wrappedPropName: keyof O,
  defValue: P
): Ref<P> {
  return computed<P>({
    get() {
      return ((propVal[wrappedPropName] as unknown) as P) || defValue
    },
    set(newValue: P): void {
      target.value = {
        ...target.value,
        [wrappedPropName]: newValue,
      }
    },
  })
}

export default defineComponent({
  name: "UserCreateUpdateFields",

  components: {
    ClientSelect,
    Password,
    PermissionInput,
  },

  props: {
    isCreate: Boolean,
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object as PropType<UserData>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n()

    const user = computed<UserData>({
      get() {
        return { ...props.value }
      },
      set(newValue: UserData): void {
        emit("input", newValue)
      },
    })

    function userPropWrapper<T>(
      propName: keyof UserData,
      defaultValue: T | undefined = undefined
    ): Ref<T | undefined> {
      return propWrapper<UserData, T | undefined>(
        props.value,
        user,
        propName,
        defaultValue
      )
    }
    const wrappedProps = {
      clientId: userPropWrapper<number>("clientId"),
      displayName: userPropWrapper<string>("displayName"),
      emailAddress: userPropWrapper<string>("emailAddress"),
      password: userPropWrapper<string>("password"),
      permissions: userPropWrapper<PermissionMap>(
        "permissions",
        createDefaultPermissions()
      ),
      userName: userPropWrapper<string>("userName"),
    }

    return {
      t,
      ...wrappedProps,
    }
  },
})
