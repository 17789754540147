






























import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
} from "@vue/composition-api"
import {
  createUser,
  CreateUserRequest,
  updateUser,
  UpdateUserRequest,
  UserData,
} from "@/_service/user"

import FormCard from "@/component/FormCard.vue"
import UserCreateUpdateFields from "./UserCreateUpdateFields.vue"
import { useI18n } from "@/_i18n"
import {
  email,
  MINIMAL_PASSWORD_LENGTH,
  minLen,
  noWhitespace,
  required,
  Validatable,
  variousPassword,
} from "@/_validation"
import { useApiCall } from "@/_use/apiCall"
import { useRouteTitle } from "@/_use/routeTitle"
import { Response } from "@/_service/http"

export default defineComponent({
  name: "UserCreateUpdate",

  components: { FormCard, UserCreateUpdateFields },

  props: {
    isCreate: Boolean,
    userData: {
      type: Object as PropType<UserData>,
      required: true,
    },
  },

  setup({ isCreate, userData }) {
    const { t } = useI18n(
      require.context("./", true, /UserCreateUpdate\.[\w-]+\.(json|ya?ml)$/i)
    )

    const mode = computed<string>(() => (isCreate ? "create" : "update"))

    useRouteTitle(() => t(`title_${mode.value}`))

    const internalUserData = reactive<UserData>(userData)

    const user = computed<UserData>({
      get() {
        return internalUserData
      },
      set(newValue: UserData): void {
        Object.assign(internalUserData, newValue)
      },
    })

    const form = ref<Validatable | undefined>(undefined)
    const successMessage = ref<string | undefined>(undefined)
    const onSuccess = () => {
      successMessage.value = t(`success_${mode.value}`, {
        name: internalUserData.userName,
      }) as string
      isCreate && form.value?.reset()
      form.value?.resetValidation()
    }

    const {
      onSubmit,
      hasErrors: isErrorVisible,
      isLoading,
      errorMessage,
    } = isCreate
      ? useApiCall<CreateUserRequest, Response>(
          createUser,
          t,
          internalUserData as CreateUserRequest,
          onSuccess
        )
      : useApiCall<UpdateUserRequest, Response>(
          updateUser,
          t,
          userData,
          onSuccess
        )

    const validationRules = {
      ...(isCreate
        ? {
            clientId: {
              ruleDefs: [[required, "validation.clientId.required"]],
            },
            userName: {
              ruleDefs: [
                [required, "validation.userName.required"],
                [noWhitespace, "validation.userName.noWhitespace"],
              ],
            },
            displayName: {
              ruleDefs: [[required, "validation.displayName.required"]],
            },
            emailAddress: {
              ruleDefs: [
                [required, "validation.email.required"],
                [email, "validation.email.email"],
              ],
            },
          }
        : {}),
      password: {
        ruleDefs: [
          ...(isCreate ? [[required, "validation.password.required"]] : []),
          [variousPassword, "validation.password.tooWeak"],
          [
            minLen(MINIMAL_PASSWORD_LENGTH),
            ["validation.password.minLen", { minLen: MINIMAL_PASSWORD_LENGTH }],
          ],
        ],
      },
    }

    return {
      errorMessage,
      isErrorVisible,
      isLoading,
      onSubmit: () => {
        successMessage.value = undefined
        onSubmit()
      },
      successMessage,
      t,
      user,
      validationRules,
    }
  },
})
